module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@bottlebooks/gatsby-plugin-tastingnotes/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@bottlebooks/gatsby-plugin-lists/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@bottlebooks/gatsby-plugin-bookmarks/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@bottlebooks/gatsby-plugin-firebase-auth/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@bottlebooks/gatsby-plugin-firebase-app/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@bottlebooks/gatsby-theme-event/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-2H0GDR20Y0"],"gtagConfig":{}},
    },{
      plugin: require('../plugins/gatsby-plugin-snipcart-advanced-master/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.3.1","publicApiKey":"ZDQ1ZjJhNWMtYzdkMS00ZDJmLThmMmMtZmU5MWZkNGFmYTY2NjM3NzgwOTc1NzE4ODE2MzI3","currency":"eur","defaultLang":"de","openCartOnAdd":"false","useSideCart":"false"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Weinmesse Berlin","short_name":"Wb","start_url":"/","background_color":"#ffffff","theme_color":"#98002e","display":"standalone","icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"848c04aef169cc82dd628bc783c69707"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-55197303-14","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"G-WX023FS2Q4","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
