import baseTheme from '@bottlebooks/gatsby-theme-event/src/theme';
import merge from 'lodash/merge';
const theme = {
  ...baseTheme,

  fonts: merge({}, baseTheme.fonts, {
    heading: 'Montserrat, sans-serif',
    body: '"Open sans", serif',
  }),
  fontSizes: {
    ...baseTheme.fontSizes,
    largestTitle: 42, // 68 in base
    largeTitle: 32, // 42 in base
    title: 24, // 32 in base
    smallTitle: 20, // 24 in base
    smallestTitle: 18, // 20 in base
  },
  fontWeights: {
    ...baseTheme.fontWeights,
    heading: '600',
  },
  title: merge({}, baseTheme.title, {
    _base: {
      WebkitFontSmoothing: 'antialiased',
    }, // Add antialiasing to titles.
  }),
  text: merge({}, baseTheme.text, {
    semiTitle: {
      ...baseTheme.text.semiTitle,
      fontSize: 'body',
    },
  }),

  styles: {
    ...baseTheme.styles,
    root: {
      ...baseTheme.styles.root,
      WebkitFontSmoothing: 'subpixel-antialiased', // Garamond and DIN look bad with antialised in small sizes.
    },
  },
};

// console.log(theme);
export default theme;
